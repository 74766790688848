











































































import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  name: "BatchesComponent",
  data: () => ({
    results: [],
    search: "",
    production:
      process.env.NODE_ENV === "production" ||
      process.env.VUE_APP_TITLE === "Datakwaliteitscontrole BRO",
  }),
  methods: {
    getresults() {
      axios
        .get(`${this.$store.state.APIurl}/batch`, {
          headers: { Authorization: `Bearer ${this.$store.state.user.token}` },
        })
        .then((response) => {
          this.$data.results = response.data;
        })
        .catch((error) => {
          this.$data.results = [];
        });
    },
    downloadReport(item: { id: string }) {
      axios({
        url: `${this.$store.state.APIurl}/batch/report/` + item.id,
        method: "GET",
        responseType: "blob",
        headers: { Authorization: `Bearer ${this.$store.state.user.token}` },
      })
        .then((response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fURL = document.createElement("a");

          fURL.href = fileURL;
          fURL.setAttribute("download", `${item.id}.pdf`);
          document.body.appendChild(fURL);

          fURL.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    startAnalysis() {
      axios({
        url: `${this.$store.state.APIurl}/batch/scan`,
        method: "GET",
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
    this.getresults();
  },
});
